import Layout from "components/layout";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useEffect,  useState } from "react";
import TranscriptionTool from "components/TranscriptionTool/TranscriptionTool";
import ToolFaq from "components/QualityTools/ToolFaq";
import { TranscriptionToolFaqData } from "components/FAQ/config";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import Loader from "components/loader";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import SEO from "components/seo";

const TranscriptionResult = () => {
  const [files, setFiles] = useState([]);
  const [outputText, setOutputText] = useState("");
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [inputLang,setInputLang] = useState("")
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const displayShareId = params.get("share-id");

  useEffect(() => {
    if (!displayShareId) return;
    const fatchAnalysis = async () => {
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );

        setInputLang(response?.meta?.language_code)
        setFiles(response);
        setOutputText(response?.result);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoader(false);
      }
    };
    fatchAnalysis();
  }, []);


  const fileClean = async (index) => {
    try {
      const updatedFiles = [...files?.meta?.file_info];
      const updatedResult = [...outputText];
      updatedFiles.splice(index, 1);
      updatedResult.splice(index, 1);

      setFiles((prevFiles) => ({
        ...prevFiles,
        meta: {
          ...prevFiles.meta,
          file_info: updatedFiles,
        },
      }));

      setOutputText(updatedResult);

      const remainingFiles = updatedResult.map((file) => {
        return {
          text: file?.text || "",
          file_info: {
            link: file?.file_info?.link,
            documentSize: file?.file_info?.documentSize,
            documentType: file?.file_info?.documentType,
            originalName: file?.file_info?.originalName,
          },
          language_code: file?.language_code || null,
        };
      });

      const remainingMetafile = {
        file_info: updatedFiles.map((file) => ({
          link: file?.link,
          documentSize: file?.documentSize,
          documentType: file?.documentType,
          originalName: file?.originalName,
        })),
        tool_name: "image_to_text",
        language_code: updatedFiles.map((file) => file?.language_code || null),
        share_id: displayShareId,
      };

      const payload = {
        tool_name: "image_to_text",
        share_id: displayShareId,
        to_save_response: remainingFiles,
        to_save_meta: remainingMetafile,
      };

      await fatchfile(payload);
    } catch (err) {
      setError(err.message || "An unexpected error occurred.");
    }
  };
  const fatchfile = async (payload) => {

    setError("");

    try {
      const response = await http().post(endpoints.forms.deleteTools, payload);
      console.log(response, "respone");
    } catch (error) {
      console.error("Error during quality analysis:", error);
      setError(error?.message || "An error occurred while uploading the file.");
    } finally {
      setLoader(false);
    }
  };

  
  const allowedFileTypes = ["pdf", "png", "jpeg", "jpg", "webp", "heic", "heif"];

  const handleFileSelect = (index) => {
    setSelectedFileIndex(index);
  };

  if (loader)
    return (
      <div className="grid place-content-center h-[calc(100vh-150px)]">
        <Loader />
      </div>
    );
  return (
    <Layout>
       <SEO
        title="Free Image to Text Converter"
        description="Convert images into editable text instantly with the Tomedes Image to Text Converter. Supports PNG, JPEG, and other formats. Fast, accurate, and free.​"
        slug="/tools/image-to-text"
      />
      <div>
        <div className="w-full py-7 ">
          <ToolsBreadcrumb />
          <div className="max-w-7xl mx-auto px-4 lg:pt-[60px] lg:pb-[60px] pt-12 pb-8 ">
            <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-2.5">
              Image to text converter
            </h1>
            <h2 className="text-[28px]  md:text-3xl leading-[48px]  text-center font-primary  font-bold ">
              Copying Text from Images Manually - Let AI Do It for You
            </h2>
            <div className="mt-12 flex lg:flex-row flex-col gap-4 ">
              <div className="relative w-full lg:w-[50%] h-[400px] pb-16">
              <div className=" shadow-box p-4  text-gray26 bg-white  h-[400px] pb-16  rounded-lg font-opensans flex flex-col gap-2 overflow-y-auto ">
                {files?.meta?.file_info?.map((item,index)=>(

                <div className="h-max   w-full ">
                <div
                    className={`flex  justify-between gap-1 ${!allowedFileTypes.includes(item?.documentType) ? " border-[#FFA4A4] bg-[#FFF5F5] " : "border-[#2A7CF8] text-black"} items-center w-full border ${
                      item?.documentSize > 20 * 1024 * 1024
                        ? "border-[#FFA4A4] bg-[#FFF5F5]"
                        : "border-[#2A7CF8]"
                    } mb-2 p-4 rounded-xl`}
                  >
                    <div className="flex justify-between w-full items-center gap-12">
                      <div className="flex items-center cursor-pointer gap-4 font-arial w-full " onClick={() => handleFileSelect(index)}>
                        <div className="relative">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="32"
                            viewBox="0 0 26 32"
                            fill="none"
                          >
                            <rect
                              x="3"
                              y="13"
                              width="18"
                              height="17"
                              fill="#00173A"
                            />
                            <rect
                              x="3"
                              y="8"
                              width="10"
                              height="22"
                              fill="#00173A"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.4001 0V8C14.4001 8.84869 14.7372 9.66263 15.3374 10.2627C15.9375 10.8629 16.7514 11.2 17.6001 11.2H25.6001V28.8C25.6001 29.6487 25.263 30.4626 24.6628 31.0627C24.0627 31.6629 23.2488 32 22.4001 32H3.2001C2.3514 32 1.53747 31.6629 0.937357 31.0627C0.33724 30.4626 9.72748e-05 29.6487 9.72748e-05 28.8V3.2C9.72748e-05 2.35131 0.33724 1.53737 0.937357 0.937258C1.53747 0.337142 2.3514 0 3.2001 0H14.4001ZM13.7697 15.2848C13.9068 15.0109 14.1196 14.782 14.3829 14.6255C14.6463 14.4689 14.949 14.3913 15.2551 14.4017C15.5613 14.4122 15.858 14.5103 16.11 14.6844C16.3621 14.8585 16.5588 15.1013 16.6769 15.384L20.6769 24.984C20.7783 25.2272 20.8182 25.4916 20.7928 25.7539C20.7675 26.0161 20.6778 26.2681 20.5317 26.4873C20.3855 26.7065 20.1876 26.8863 19.9553 27.0106C19.723 27.135 19.4636 27.2 19.2001 27.2H6.4001C6.12747 27.1999 5.8594 27.1301 5.62133 26.9972C5.38326 26.8644 5.18308 26.6729 5.03979 26.441C4.89649 26.209 4.81484 25.9443 4.80257 25.672C4.79031 25.3996 4.84784 25.1287 4.9697 24.8848L8.1697 18.4848C8.29168 18.241 8.47393 18.0324 8.69917 17.8788C8.92441 17.7252 9.18517 17.6317 9.45669 17.6072C9.72821 17.5826 10.0015 17.6279 10.2506 17.7387C10.4997 17.8494 10.7164 18.022 10.8801 18.24L11.7281 19.3696L13.7697 15.2848ZM11.2001 12C11.2001 11.3635 10.9472 10.753 10.4972 10.3029C10.0471 9.85286 9.43662 9.6 8.8001 9.6C8.16358 9.6 7.55313 9.85286 7.10304 10.3029C6.65295 10.753 6.4001 11.3635 6.4001 12C6.4001 12.6365 6.65295 13.247 7.10304 13.6971C7.55313 14.1471 8.16358 14.4 8.8001 14.4C9.43662 14.4 10.0471 14.1471 10.4972 13.6971C10.9472 13.247 11.2001 12.6365 11.2001 12Z"
                              fill="#9AB4DB"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M17.6002 0.353516V7.99992H25.2466C25.0946 7.70456 24.8976 7.43466 24.6626 7.19992L18.4002 0.937516C18.1656 0.701941 17.8957 0.50597 17.6002 0.353516Z"
                              fill="#00173A"
                            />
                          </svg>
                        </div>

                        <p className="flex flex-col">
                          <span
                            className={`text-[#0B0B0B]   ${!allowedFileTypes.includes(item?.documentType) ? " border-[#FFA4A4] bg-[#FFF5F5] text-[#ff0000]" : "border-[#2A7CF8] text-black"}  font-opensans text-sm font-normal`}
                            style={{ wordBreak: "break-all" }}
                          >
                            {item?.originalName}
                          </span>
                          <span
                            className={`text-[12px]   ${!allowedFileTypes.includes(item?.documentType) ? " border-[#FFA4A4] bg-[#FFF5F5] text-[#ff0000]" : "border-[#2A7CF8] text-black"}  font-opensans font-bold text-[#8B8B8B] uppercase `}
                          >
                            {item?.documentSize}{" "}
                            <span className="ml-2.5">
                              {item?.documentType}
                            </span>
                          </span>
                        </p>
                      </div>
                      <div
                        onClick={() => fileClean(index)}
                        className="cursor-pointer h-6 w-6 flex items-center justify-center bg-transparent filter-black"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M0.314703 0.814703C0.414208 0.714947 0.532416 0.6358 0.662555 0.581798C0.792695 0.527796 0.93221 0.5 1.07311 0.5C1.21401 0.5 1.35352 0.527796 1.48366 0.581798C1.6138 0.6358 1.73201 0.714947 1.83152 0.814703L7.50028 6.48561L13.169 0.814703C13.2686 0.715108 13.3869 0.636104 13.517 0.582204C13.6471 0.528303 13.7866 0.500561 13.9275 0.500561C14.0683 0.500561 14.2078 0.528303 14.3379 0.582204C14.468 0.636104 14.5863 0.715108 14.6859 0.814703C14.7855 0.914299 14.8645 1.03254 14.9184 1.16266C14.9723 1.29279 15 1.43226 15 1.57311C15 1.71396 14.9723 1.85343 14.9184 1.98356C14.8645 2.11368 14.7855 2.23192 14.6859 2.33152L9.01495 8.00028L14.6859 13.669C14.7855 13.7686 14.8645 13.8869 14.9184 14.017C14.9723 14.1471 15 14.2866 15 14.4275C15 14.5683 14.9723 14.7078 14.9184 14.8379C14.8645 14.968 14.7855 15.0863 14.6859 15.1859C14.5863 15.2855 14.468 15.3645 14.3379 15.4184C14.2078 15.4723 14.0683 15.5 13.9275 15.5C13.7866 15.5 13.6471 15.4723 13.517 15.4184C13.3869 15.3645 13.2686 15.2855 13.169 15.1859L7.50028 9.51495L1.83152 15.1859C1.73192 15.2855 1.61368 15.3645 1.48356 15.4184C1.35343 15.4723 1.21396 15.5 1.07311 15.5C0.932261 15.5 0.79279 15.4723 0.662663 15.4184C0.532535 15.3645 0.414299 15.2855 0.314703 15.1859C0.215108 15.0863 0.136104 14.968 0.0822038 14.8379C0.0283032 14.7078 0.000560843 14.5683 0.000560843 14.4275C0.000560843 14.2866 0.0283032 14.1471 0.0822038 14.017C0.136104 13.8869 0.215108 13.7686 0.314703 13.669L5.98561 8.00028L0.314703 2.33152C0.214947 2.23201 0.1358 2.1138 0.0817982 1.98366C0.0277962 1.85352 0 1.71401 0 1.57311C0 1.43221 0.0277962 1.2927 0.0817982 1.16256C0.1358 1.03242 0.214947 0.914208 0.314703 0.814703Z"
                            fill="#00173A"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
          
                ))}
                <div className="p-4 absolute bottom-0 py-6 rounded-lg  bg-white  w-full left-0 right-0">
                  <button
                    onClick={() => {navigate(`/tools/image-to-text?new_file=true`);setFiles("")}}
                    className="flex items-center gap-2 text-[#5B93FF] font-opensans font-semibold"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M8 1.5V15.5M1 8.5H15"
                        stroke="#5B93FF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Convert new file
                  </button>
                </div>
              </div>
              </div>
              <TranscriptionTool
                  outputText={outputText[selectedFileIndex] || ""}
                // outputText={outputText}
                loader={loader}
                error={error}
                textconvert={true}
                inputLang={inputLang}
                isImageText = {true}
              />
            </div>
          </div>
          <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <p className="mt-8 max-w-5xl mx-auto md:mt-12 text-lg text-center font-opensans">
            This Image to Text Converter transforms static images into editable,
            searchable text with just a click. Upload your PNG, JPEG, WEBP, HEIC
            or HEIF files, and let AI do the rest. Ideal for extracting notes,
            digitizing printed documents, or capturing text from visuals, this
            tool helps you save time, reduce manual effort, and focus on what
            matters most.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={TranscriptionToolFaqData} />
    </Layout>
  );
};

export default TranscriptionResult;
