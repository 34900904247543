import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import Loader from "components/loader";
import http from "utils/http";
import { endpoints } from "utils/endpoints";

const TranscriptionTool = ({
  outputText,
  error,
  loader,
  textconvert,
  inputLang,
  isImageText,
  gptText,
  displayShareId,
  geminiText,
  setGeminiText,
}) => {
  const [copySuccess, setCopySuccess] = useState("Copy");
  const [wordCount, setWordCount] = useState("");
  const [geminiLoader, setGeminiLoader] = useState(geminiText ? false : true);
  const outputRef = useRef("");
  const wordsLength = (text) => {
    const length = text
      ?.trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
    return length ?? 0;
  };
  const copyText = () => {
    navigator.clipboard.writeText(outputText);
    setCopySuccess("Copied");
    setTimeout(() => {
      setCopySuccess("Copy");
    }, 1500);
  };
  const allowedFileTypes = [
    "pdf",
    "png",
    "jpeg",
    "jpg",
    "webp",
    "heic",
    "heif",
  ];
  const isFileAllowedType = (fileInfo) => {
    return allowedFileTypes.includes(fileInfo?.documentType);
  };
  // const isLargeFile = (fileInfo) => {
  //   console.log(fileInfo,"fileinfo")
  //   const isFileAllowedType = allowedFileTypes.includes(fileInfo?.documentType);
  //   console.log(isFileAllowedType,"isfileallowdtype")
  //   const fileSizeKB = parseInt(fileInfo?.documentSize, 10);
  //   const isFileLarge = fileSizeKB > 20480;

  //   return isFileAllowedType && isFileLarge;
  // };
  useEffect(() => {
    const wordLength = (outputText?.text || "")
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;

    setWordCount(wordLength);
  }, [outputText, setWordCount]);

  useEffect(() => {
    if (!displayShareId || geminiText) return;
    const intervalId = setInterval(async () => {
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        if (
          response?.result?.result?.gemini_result?.transcription
            ?.transcription ||
          response?.result?.result?.gemini_result?.transcription?.status ===
            "completed"
        ) {
          clearInterval(intervalId);
          setGeminiText(
            response?.result?.result?.gemini_result?.transcription
              ?.transcription ??
              response?.result?.result?.gemini_result?.transcription?.message
          );
          setGeminiLoader(false);
        }
      } catch (error) {
        setGeminiLoader(false);
      }
    }, 4000);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);
  const downloadText = async () => {
    try {
      const textToDownload = document.querySelector("#output_text").innerText;
      if (!textToDownload) {
        console.error("No text found to download.");
        return;
      }
      const blob = new Blob([textToDownload], { type: "text/plain" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = `${textconvert ? "image-text" : "Transcription-text"}`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };

  return (
    <div
      className={`relative shadow-box p-4 flex-1 text-gray26  bg-white rounded-lg font-opensans ${
        outputText ? "border border-[#5B93FF] " : ""
      }`}
    >
      <div className="flex justify-between items-center">
        <span className={`font-bold text-lg text-[#2B2B2B]`}>
          {`${textconvert ? "Extracted Text" : "Transcribed text"}`}
        </span>
        {outputText ? (
          <div className="flex gap-3">
            <svg
              data-tooltip-id="copy-tooltip"
              data-tooltip-content={copySuccess}
              onClick={copyText}
              className="cursor-pointer border-none outline-none"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.28628 0C5.86193 0 5.45497 0.168571 5.15491 0.468629C4.85485 0.768687 4.68628 1.17565 4.68628 1.6V3.2H6.28628V1.6H15.0863V10.4H13.4863V12H15.0863C15.5106 12 15.9176 11.8314 16.2177 11.5314C16.5177 11.2313 16.6863 10.8243 16.6863 10.4V1.6C16.6863 1.17565 16.5177 0.768687 16.2177 0.468629C15.9176 0.168571 15.5106 0 15.0863 0H6.28628ZM2.28628 4C1.86193 4 1.45497 4.16857 1.15491 4.46863C0.85485 4.76869 0.686279 5.17565 0.686279 5.6V14.4C0.686279 14.8243 0.85485 15.2313 1.15491 15.5314C1.45497 15.8314 1.86193 16 2.28628 16H11.0863C11.5106 16 11.9176 15.8314 12.2177 15.5314C12.5177 15.2313 12.6863 14.8243 12.6863 14.4V5.6C12.6863 5.17565 12.5177 4.76869 12.2177 4.46863C11.9176 4.16857 11.5106 4 11.0863 4H2.28628ZM2.28628 5.6H11.0863V14.4H2.28628V5.6Z"
                fill="#5B93FF"
              />
            </svg>
            <svg
              onClick={downloadText}
              className="cursor-pointer outline-none border-none"
              data-tooltip-id="download-tooltip"
              data-tooltip-content="Download"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="15"
              viewBox="0 0 17 15"
              fill="none"
            >
              <path
                d="M2.72549 14.8572C2.18466 14.8572 1.66598 14.6423 1.28355 14.2599C0.901125 13.8775 0.686279 13.3588 0.686279 12.8179V9.90477C0.686279 9.67299 0.778356 9.45069 0.942253 9.2868C1.10615 9.1229 1.32844 9.03082 1.56023 9.03082C1.79201 9.03082 2.01431 9.1229 2.1782 9.2868C2.3421 9.45069 2.43418 9.67299 2.43418 9.90477V12.8179C2.43418 12.9787 2.56469 13.1093 2.72549 13.1093H14.9608C15.0381 13.1093 15.1121 13.0786 15.1668 13.0239C15.2214 12.9693 15.2521 12.8952 15.2521 12.8179V9.90477C15.2521 9.67299 15.3442 9.45069 15.5081 9.2868C15.672 9.1229 15.8943 9.03082 16.1261 9.03082C16.3578 9.03082 16.5801 9.1229 16.744 9.2868C16.9079 9.45069 17 9.67299 17 9.90477V12.8179C17 13.3588 16.7852 13.8775 16.4027 14.2599C16.0203 14.6423 15.5016 14.8572 14.9608 14.8572H2.72549Z"
                fill="#5B93FF"
              />
              <path
                d="M7.96919 7.50315V0.87395C7.96919 0.642164 8.06126 0.419871 8.22516 0.255974C8.38906 0.0920766 8.61135 0 8.84314 0C9.07492 0 9.29721 0.0920766 9.46111 0.255974C9.62501 0.419871 9.71709 0.642164 9.71709 0.87395V7.50315L12.0127 5.20874C12.0938 5.12764 12.19 5.0633 12.296 5.01941C12.402 4.97552 12.5156 4.95292 12.6303 4.95292C12.7449 4.95292 12.8585 4.97552 12.9645 5.01941C13.0705 5.0633 13.1667 5.12764 13.2478 5.20874C13.3289 5.28984 13.3933 5.38613 13.4372 5.49209C13.4811 5.59806 13.5037 5.71163 13.5037 5.82633C13.5037 5.94103 13.4811 6.0546 13.4372 6.16057C13.3933 6.26653 13.3289 6.36282 13.2478 6.44392L9.46073 10.231C9.37967 10.3122 9.2834 10.3766 9.17742 10.4206C9.07145 10.4645 8.95786 10.4871 8.84314 10.4871C8.72842 10.4871 8.61482 10.4645 8.50885 10.4206C8.40288 10.3766 8.30661 10.3122 8.22555 10.231L4.43843 6.44392C4.35733 6.36282 4.29299 6.26653 4.2491 6.16057C4.20521 6.0546 4.18262 5.94103 4.18262 5.82633C4.18262 5.71163 4.20521 5.59806 4.2491 5.49209C4.29299 5.38613 4.35733 5.28984 4.43843 5.20874C4.51953 5.12764 4.61582 5.0633 4.72179 5.01941C4.82775 4.97552 4.94133 4.95292 5.05602 4.95292C5.17072 4.95292 5.28429 4.97552 5.39026 5.01941C5.49623 5.0633 5.59251 5.12764 5.67361 5.20874L7.96919 7.50315Z"
                fill="#5B93FF"
              />
            </svg>
            <Tooltip
              id="copy-tooltip"
              place="top"
              multiline={true}
              className="z-50"
            />
            <Tooltip
              id="download-tooltip"
              place="top"
              multiline={true}
              className="z-50"
            />
          </div>
        ) : (
          <div className="flex gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.28652 0C5.86218 0 5.45521 0.168571 5.15515 0.468629C4.85509 0.768687 4.68652 1.17565 4.68652 1.6V3.2H6.28652V1.6H15.0865V10.4H13.4865V12H15.0865C15.5109 12 15.9178 11.8314 16.2179 11.5314C16.518 11.2313 16.6865 10.8243 16.6865 10.4V1.6C16.6865 1.17565 16.518 0.768687 16.2179 0.468629C15.9178 0.168571 15.5109 0 15.0865 0H6.28652ZM2.28652 4C1.86218 4 1.45521 4.16857 1.15515 4.46863C0.855094 4.76869 0.686523 5.17565 0.686523 5.6V14.4C0.686523 14.8243 0.855094 15.2313 1.15515 15.5314C1.45521 15.8314 1.86218 16 2.28652 16H11.0865C11.5109 16 11.9178 15.8314 12.2179 15.5314C12.518 15.2313 12.6865 14.8243 12.6865 14.4V5.6C12.6865 5.17565 12.518 4.76869 12.2179 4.46863C11.9178 4.16857 11.5109 4 11.0865 4H2.28652ZM2.28652 5.6H11.0865V14.4H2.28652V5.6Z"
                fill="#9C9C9C"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="15"
              viewBox="0 0 17 15"
              fill="none"
            >
              <path
                d="M2.72574 14.8571C2.18491 14.8571 1.66622 14.6422 1.2838 14.2598C0.901369 13.8774 0.686523 13.3587 0.686523 12.8179V9.90471C0.686523 9.67293 0.7786 9.45063 0.942497 9.28674C1.10639 9.12284 1.32869 9.03076 1.56047 9.03076C1.79226 9.03076 2.01455 9.12284 2.17845 9.28674C2.34235 9.45063 2.43442 9.67293 2.43442 9.90471V12.8179C2.43442 12.9787 2.56493 13.1092 2.72574 13.1092H14.961C15.0383 13.1092 15.1124 13.0785 15.167 13.0239C15.2217 12.9692 15.2523 12.8951 15.2523 12.8179V9.90471C15.2523 9.67293 15.3444 9.45063 15.5083 9.28674C15.6722 9.12284 15.8945 9.03076 16.1263 9.03076C16.3581 9.03076 16.5804 9.12284 16.7443 9.28674C16.9082 9.45063 17.0002 9.67293 17.0002 9.90471V12.8179C17.0002 13.3587 16.7854 13.8774 16.403 14.2598C16.0205 14.6422 15.5019 14.8571 14.961 14.8571H2.72574Z"
                fill="#9C9C9C"
              />
              <path
                d="M7.96919 7.50315V0.87395C7.96919 0.642164 8.06126 0.419871 8.22516 0.255974C8.38906 0.0920766 8.61135 0 8.84314 0C9.07492 0 9.29721 0.0920766 9.46111 0.255974C9.62501 0.419871 9.71709 0.642164 9.71709 0.87395V7.50315L12.0127 5.20874C12.0938 5.12764 12.19 5.0633 12.296 5.01941C12.402 4.97552 12.5156 4.95292 12.6303 4.95292C12.7449 4.95292 12.8585 4.97552 12.9645 5.01941C13.0705 5.0633 13.1667 5.12764 13.2478 5.20874C13.3289 5.28984 13.3933 5.38613 13.4372 5.49209C13.4811 5.59806 13.5037 5.71163 13.5037 5.82633C13.5037 5.94103 13.4811 6.0546 13.4372 6.16057C13.3933 6.26653 13.3289 6.36282 13.2478 6.44392L9.46073 10.231C9.37967 10.3122 9.2834 10.3766 9.17742 10.4206C9.07145 10.4645 8.95786 10.4871 8.84314 10.4871C8.72842 10.4871 8.61482 10.4645 8.50885 10.4206C8.40288 10.3766 8.30661 10.3122 8.22555 10.231L4.43843 6.44392C4.35733 6.36282 4.29299 6.26653 4.2491 6.16057C4.20521 6.0546 4.18262 5.94103 4.18262 5.82633C4.18262 5.71163 4.20521 5.59806 4.2491 5.49209C4.29299 5.38613 4.35733 5.28984 4.43843 5.20874C4.51953 5.12764 4.61582 5.0633 4.72179 5.01941C4.82775 4.97552 4.94133 4.95292 5.05602 4.95292C5.17072 4.95292 5.28429 4.97552 5.39026 5.01941C5.49623 5.0633 5.59251 5.12764 5.67361 5.20874L7.96919 7.50315Z"
                fill="#9C9C9C"
              />
            </svg>
          </div>
        )}
      </div>
      <hr
        className={`border mt-2 mb-4 ${
          outputText ? "border-[#B0B0B0]" : "border-[#D2D2D2]"
        }`}
      />
      {!outputText && <div className="lg:h-0 h-[140px]" />}
      {!isImageText ? (
        <div>
          {loader ? (
            <div className="h-full flex items-center justify-center flex-col">
              <Loader />
            </div>
          ) : outputText ? (
            <div
              ref={outputRef}
              className={`flex items-center  justify-center flex-col ${
                !outputText ? "-mt-5" : ""
              }`}
            >
              <div
                id="transcription_tool_result1"
                className="bg-[#F5F5F5] p-2  border border-[#DEDEDE] rounded flex flex-col gap-2.5  w-full"
              >
                <p className="text-[#0A2641] text-sm font-opensans font-semibold">
                  Result 1
                </p>
                {outputText?.chat_gpt_result?.transcription && (
                  <p
                    dir={
                      inputLang === "fa" ||
                      inputLang === "he" ||
                      inputLang === "ar"
                        ? "rtl"
                        : "ltr"
                    }
                    id="output_text"
                    className="text-sm leading-6 text-[#424242] font-opensans font-normal anonymize_tool overflow-y-auto lg:h-[100px] h-full pr-2 "
                    dangerouslySetInnerHTML={{
                      __html: outputText?.chat_gpt_result?.transcription
                        ?.replace(/\n\n/g, "<br><br>")
                        .replace(/\n/g, "<br>"),
                    }}
                  />
                )}
                <p className="text-sm font-opensans text-[#9C9C9C] font-semibold">
                  {wordsLength(outputText?.chat_gpt_result?.transcription)}{" "}
                  words
                </p>
              </div>
              <div
                id="transcription_tool_result2"
                className="bg-[#F5F5F5] p-2  border border-[#DEDEDE] rounded flex flex-col gap-2.5 mt-4 w-full"
              >
                <p className="text-[#0A2641] text-sm font-opensans font-semibold">
                  Result 2
                </p>

                {geminiText ? (
                  <p
                    dir={
                      inputLang === "fa" ||
                      inputLang === "he" ||
                      inputLang === "ar"
                        ? "rtl"
                        : "ltr"
                    }
                    id="output_text"
                    className="text-sm leading-6 text-[#424242] font-opensans font-normal anonymize_tool overflow-y-auto lg:h-[100px] h-full pr-2 "
                    dangerouslySetInnerHTML={{
                      __html: geminiText
                        ?.replace(/\n\n/g, "<br><br>")
                        .replace(/\n/g, "<br>"),
                    }}
                  />
                ) : (
                  <div className="lg:h-[100px] h-full grid place-content-center">
                    <Loader />
                  </div>
                )}
                <p className="text-sm font-opensans text-[#9C9C9C] font-semibold">
                  {wordsLength(geminiText)} words
                </p>
              </div>
            </div>
          ) : (
            <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
              {error}
            </span>
          )}
        </div>
      ) : (
        <>
          {loader ? (
            <div className="h-full flex items-center justify-center flex-col">
              <Loader />
            </div>
          ) : outputText ? (
            <div
              ref={outputRef}
              className={`pb-[50px] flex items-center  justify-center flex-col  ${
                !outputText ? "-mt-5" : ""
              }`}
            >
              <div className="anonymize_tool  overflow-y-auto lg:h-[235px] h-full w-full ">
                {/* {outputText.map((item, index) => ( */}
                <div>
                  <div className="mb-4 flex-col gap-2 ">
                    {isFileAllowedType(outputText?.file_info) && (
                      <div className="flex items-center justify-center w-full">
                        {outputText?.file_info?.link?.endsWith(".pdf") ? (
                          <iframe
                            src={`https://docs.google.com/gview?url=${outputText?.file_info?.link}&embedded=true`}
                            width="600"
                            height="500"
                            sandbox="allow-scripts allow-same-origin"
                            style="border: none"
                          ></iframe>
                        ) : (
                          <img
                            src={outputText?.file_info?.link}
                            alt={outputText?.file_info?.originalName}
                            className="w-[230px] h-[200px] rounded-lg"
                          />
                        )}

                        {/* <img
                        src={outputText?.file_info?.link}
                        alt={outputText?.file_info?.originalName}
                        className="w-[230px] h-[200px] rounded-lg"
                      /> */}
                      </div>
                    )}
                    <p
                      dir={
                        inputLang === "fa" ||
                        inputLang === "he" ||
                        inputLang === "ar"
                          ? "rtl"
                          : "ltr"
                      }
                      id="output_text"
                      className={`text-base ${
                        !isFileAllowedType(outputText?.file_info)
                          ? "text-[#ff0000]"
                          : "text-black"
                      } mt-2 font-opensans font-normal`}
                      dangerouslySetInnerHTML={{
                        __html: outputText?.text
                          ?.replace(/\n\n/g, "<br><br>")
                          .replace(/\n/g, "<br>"),
                      }}
                    />
                  </div>
                </div>
                {/* ))} */}
              </div>
            </div>
          ) : (
            <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
              {error}
            </span>
          )}
        </>
      )}
      <div className="bg-white rounded-md p-3 flex items-center gap-5 justify-between mt-3 w-[94%] absolute bottom-[14px] left-0 mx-4 ">
        <p className="text-[12px] w-full text-[#6E6E6E] font-opensans">
          {wordCount ? `${wordCount} words` : "-- words"}
        </p>
      </div>
    </div>
  );
};

export default TranscriptionTool;
